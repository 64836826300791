<template>
      <div>

  <div  :class="flag  ? 'allbaoGe_all' : 'allbaoGe_alltwo'">
    <!-- 数字多宝阁 -->
    <div class="image">
      <img :src="banners" alt="" />
    </div>
    <div class="aaaaa">
      <div class="paione" v-show="flag">
        <div class="OneIm">
          <div class="OneLeft"  @mousedown.stop="an" @mouseup.stop="song(ones.id)">
            <div class="detailsList">
              <span class="detailsTitleText" @mousedown.stop="an" @mouseup.stop="song(ones.id)">{{ ones.name }}</span
              ><br />
              <span
                class="detailsJianText"
                id="fontSix"
                @mousedown.stop="an"
                @mouseup.stop="song(ones.id)"
              >
                {{ ones.one_tag }} </span
              ><br />
              <span
                class="detailsxiangText"
                @mousedown.stop="an"
                @mouseup.stop="song(ones.id)"
                >{{ ones.two_tag }}</span
              >
            </div>
          </div>
          <div class="Oneright" @mouseup="skipDetails(one.id)">
            <div class="baies">

            <img :src="one.img" alt="" />
            <div class="suzhi_head"></div>
            <div class="detailsList">
              <span
                class="detailsTitleText colorZong"
                @mousedown.stop="an"
                @mouseup.stop="song(one.id)"
                >{{ one.name }}</span
              ><br />
              <span
                class="detailsJianText colorZong"
                @mousedown.stop="an"
                @mouseup.stop="song(one.id)"
                id="fontSix"
                >{{ one.one_tag }}</span
              ><br />
              <span
                class="detailsxiangText colorZong"
                @mousedown.stop="an"
                @mouseup.stop="song(one.id)"
                >{{ one.two_tag }}</span
              >
            </div>
          </div>
            </div>
        </div>
        <div class="twoImgs">
          <div class="twoLeft" @mouseup="skipDetails(two.id)">
            <div class="detailsList mad">
              <span
                class="detailsTitleText"
                @mousedown.stop="an"
                @mouseup.stop="song(two.id)"
                >{{ two.name }}</span
              ><br />
              <span
                class="detailsJianText"
                @mousedown.stop="an"
                @mouseup.stop="song(two.id)"
                >{{ two.one_tag }}</span
              ><br />
              <span
                class="detailsxiangText"
                @mousedown.stop="an"
                @mouseup.stop="song(two.id)"
                >{{ two.two_tag }}</span
              >
            </div>
          </div>
          <div class="tworight">
            <div class="twoRighOne" @mouseup="skipDetails(three.id)">
              <div class="detailsList">
                <span
                  class="detailsTitleText"
                  @mousedown.stop="an"
                  @mouseup.stop="song(three.id)"
                  >{{ three.name }}</span
                ><br />
                <span
                  class="detailsJianText"
                  @mousedown.stop="an"
                  @mouseup.stop="song(three.id)"
                  >{{ three.one_tag }}</span
                ><br />
                <span
                  class="detailsxiangText"
                  @mousedown.stop="an"
                  @mouseup.stop="song(three.id)"
                  >{{ three.two_tag }}</span
                >
              </div>
            </div>
            <div class="twoRighTwo" @mouseup="skipDetails(four.id)">
              <div class="detailsList">
                <span
                  class="detailsTitleText"
                  @mousedown.stop="an"
                  @mouseup.stop="song(four.id)"
                  >{{ four.name }}</span
                ><br />
                <span
                  class="detailsJianText"
                  id="fontSeven"
                  @mousedown.stop="an"
                  @mouseup.stop="song(four.id)"
                  >{{ four.one_tag }}</span
                ><br />
                <span
                  class="detailsxiangText"
                  @mousedown.stop="an"
                  @mouseup.stop="song(four.id)"
                  >{{ four.two_tag }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="threeImg" @mouseup="skipDetails(five.id)">
          <div class="detailsLists" style="float: left">
            <span
              class="detailsTitleText colorLu"
              id="colorLu"
              @mousedown.stop="an"
              @mouseup.stop="song(five.id)"
              >{{ five.name }}</span
            ><br />
            <span
              class="detailsJianText colorLu"
              id="colorLu"
              @mousedown.stop="an"
              @mouseup.stop="song(five.id)"
              >{{ five.one_tag }}</span
            ><br />
            <span
              class="detailsxiangText colorLu"
              id="colorLu"
              @mousedown.stop="an"
              @mouseup.stop="song(five.id)"
              >{{ five.two_tag }}</span
            >
          </div>
          <img :src="five.img" alt="" />
        </div>
        <div class="OneImgs">
          <div class="Fourright" @mouseup="skipDetails(seven.id)">
            <div class="panzi">
              <span
                class="detailsTitleText"
                @mousedown.stop="an"
                @mouseup.stop="song(seven.id)"
                >{{ seven.name }}</span
              ><br />
              <span
                class="detailsJianText"
                @mousedown.stop="an"
                @mouseup.stop="song(seven.id)"
                >{{ seven.one_tag }}</span
              ><br />
              <span
                class="detailsxiangText"
                @mousedown.stop="an"
                @mouseup.stop="song(seven.id)"
                >{{ seven.two_tag }}</span
              >
            </div>
          </div>
          <div class="fourLeft" @mouseup="skipDetails(six.id)">
            <div class="panzi">
              <span
                class="detailsTitleText pan"
                @mousedown.stop="an"
                @mouseup.stop="song(six.id)"
                >{{ six.name }}</span
              ><br />
              <span
                class="detailsJianText pan"
                @mousedown.stop="an"
                @mouseup.stop="song(six.id)"
                >{{ six.one_tag }}</span
              ><br />
              <span
                class="detailsxiangText pan"
                @mousedown.stop="an"
                @mouseup.stop="song(six.id)"
                >{{ six.two_tag }}</span
              >
            </div>
          </div>
        </div>
        <div class="fiveImg" @mouseup="skipDetails(eight.id)">
          <div class="detailsLis" style="float: left">
            <span
              class="detailsTitleText colorLu"
              @mousedown.stop="an"
              @mouseup.stop="song(eight.id)"
              >{{ eight.name }}</span
            ><br />
            <span
              class="detailsJianText colorLu"
              @mousedown.stop="an"
              @mouseup.stop="song(eight.id)"
              >{{ eight.one_tag }}</span
            ><br />
            <span
              class="detailsxiangText colorLu"
              @mousedown.stop="an"
              @mouseup.stop="song(eight.id)"
              >{{ eight.two_tag }}</span
            >
          </div>
          <img :src="eight.img" alt="" />
        </div>
        <div class="OneImgs">
          <div class="sixLeft" @mouseup="skipDetails(night.id)">
            <div class="detailsList" style="">
              <span
                class="detailsTitleText"
                @mousedown.stop="an"
                @mouseup.stop="song(night.id)"
                >{{ night.name }}</span
              ><br />
              <span
                class="detailsJianText"
                @mousedown.stop="an"
                @mouseup.stop="song(night.id)"
                >{{ night.one_tag }}</span
              ><br />
              <span
                class="detailsxiangText"
                @mousedown.stop="an"
                @mouseup.stop="song(night.id)"
                >{{ night.two_tag }}</span
              >
            </div>
          </div>
          <div class="sixRight">
            <div class="detailsList" @mouseup="skipDetails(ten.id)">
              <span
                class="detailsTitleText pan"
                @mousedown.stop="an"
                @mouseup.stop="song(ten.id)"
                >{{ ten.name }}</span
              ><br />
              <span
                class="detailsJianText pan"
                @mousedown.stop="an"
                @mouseup.stop="song(ten.id)"
                >{{ ten.one_tag }}</span
              ><br />
              <span
                class="detailsxiangText pan"
                @mousedown.stop="an"
                @mouseup.stop="song(ten.id)"
                >{{ ten.two_tag }}</span
              >
            </div>
            <img :src="ten.img" @click="skipDetails(ten.id)" alt="" />
          </div>
        </div>
        <div class="sevenImg">
          <img :src="twos.img" id="sevenImgs" @click="skipDetails(twos.id)" alt="" />
          <div class="seven" @mouseup="skipDetails(twos.id)">
            <div class="detailsListsdss" style="float: right">
              <span
                class="detailsTitleText"
                @mousedown.stop="an"
                @mouseup.stop="song(twos.id)"
                >{{ twos.name }}</span
              ><br />
              <span
                class="detailsJianText"
                @mousedown.stop="an"
                @mouseup.stop="song(twos.id)"
                >{{ twos.one_tag }}</span
              ><br />
              <span
                class="detailsxiangText"
                @mousedown.stop="an"
                @mouseup.stop="song(twos.id)"
                >{{ twos.two_tag }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="paitwo" v-show="!flag">
        <div class="tongluImg" style="height: 475px">
          <div
            class="tongxiang"
            style="float: left"
            @mousedown.stop="an"
            @mouseup="skipDetails(Twoling.id)"
          >
            <span
              class="detailsTitleText"
              id="colorLu"
              @mousedown.stop="an"
              @mouseup.stop="song(Twoling.id)"
              >{{ Twoling.name }}</span
            ><br />
            <span
              class="detailsJianText"
              id="colorLu"
              @mousedown.stop="an"
              @mouseup.stop="song(Twoling.id)"
              >{{ Twoling.one_tag }}</span
            ><br />
            <span
              class="detailsxiangText"
              id="colorLu"
              @mousedown.stop="an"
              @mouseup.stop="song(Twoling.id)"
              >{{ Twoling.two_tag }}</span
            >
          </div>
          <img id="tongxianglu"  @click="skipDetails(Twoling.id)" :src="Twoling.img" alt="" />
        </div>
        <div class="twoImgs">
          <div class="twoLefted" @mouseup="skipDetails(Twoone.id)">
            <div class="detailsList mad">
              <span
                class="detailsTitleText"
                @mousedown.stop="an"
                @mouseup.stop="song(Twoone.id)"
                >{{ Twoone.name }}</span
              ><br />
              <span
                class="detailsJianText"
                @mousedown.stop="an"
                @mouseup.stop="song(Twoone.id)"
                >{{ Twoone.one_tag }}</span
              ><br />
              <span
                class="detailsxiangText"
                @mousedown.stop="an"
                @mouseup.stop="song(Twoone.id)"
                >{{ Twoone.two_tag }}</span
              >
            </div>
          </div>
          <div class="tworight">
            <div class="twoRighOneed" @mouseup="skipDetails(Twotwo.id)">
              <div class="detailsList">
                <span
                  class="detailsTitleText"
                  @mousedown.stop="an"
                  @mouseup.stop="song(Twotwo.id)"
                  >{{ Twotwo.name }}</span
                ><br />
                <span
                  class="detailsJianText"
                  @mousedown.stop="an"
                  @mouseup.stop="song(Twotwo.id)"
                  >{{ Twotwo.one_tag }}</span
                ><br />
                <span
                  class="detailsxiangText"
                  @mousedown.stop="an"
                  @mouseup.stop="song(Twotwo.id)"
                  >{{ Twotwo.two_tag }}</span
                >
              </div>
            </div>
            <div class="twoRighTwoed" @mouseup="skipDetails(Twothree.id)">
              <div class="detailsListed">
                <span
                  class="detailsTitleText"
                  @mousedown.stop="an"
                  @mouseup.stop="song(Twothree.id)"
                  >{{ Twothree.name }}</span
                ><br />
                <span
                  class="detailsJianText"
                  @mousedown.stop="an"
                  @mouseup.stop="song(Twothree.id)"
                  >{{ Twothree.one_tag }}</span
                ><br />
                <span
                  class="detailsxiangText"
                  @mousedown.stop="an"
                  @mouseup.stop="song(Twothree.id)"
                  >{{ Twothree.two_tag }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="twoImgs">
          <div class="twoLeftsed" @mouseup="skipDetails(Twosix.id)">
            <div class="detailsList mad">
              <span
                class="detailsTitleText"
                @mousedown.stop="an"
                @mouseup.stop="song(Twosix.id)"
                >{{ Twosix.name }}</span
              ><br />
              <span
                class="detailsJianText"
                @mousedown.stop="an"
                @mouseup.stop="song(Twosix.id)"
                >{{ Twosix.one_tag }}</span
              ><br />
              <span
                class="detailsxiangText"
                @mousedown.stop="an"
                @mouseup.stop="song(Twosix.id)"
                >{{ Twosix.two_tag }}</span
              >
            </div>
          </div>
          <div class="tworights">
            <div class="twoRighOneedsed" @mouseup="skipDetails(Twofour.id)">
              <div class="TwoPage" id="TwoPagePadTop">
                <span
                  class="detailsTitleTextBottom"
                  @mousedown.stop="an"
                  @mouseup.stop="song(Twofour.id)"
                  id="sanzu"
                  >{{ Twofour.name }}</span
                ><br />
                <span
                  class="detailsJianText"
                  id="sanzu"
                  @mousedown.stop="an"
                  @mouseup.stop="song(Twofour.id)"
                  >{{ Twofour.one_tag }}</span
                ><br />
                <span
                  class="detailsxiangText"
                  id="sanzu"
                  @mousedown.stop="an"
                  @mouseup.stop="song(Twofour.id)"
                  >{{ Twofour.two_tag }}</span
                >
              </div>
            </div>
            <div class="twoRighTwoedsed" @mouseup="skipDetails(Twofive.id)">
              <div class="TwoPage" id="TwoPagePadTwo" style="paddingtop: 81px">
                <span
                  class="detailsTitleTextdeng"
                  @mousedown.stop="an"
                  @mouseup.stop="song(Twofive.id)"
                  >{{ Twofive.name }}</span
                ><br />
                <span
                  class="detailsJianText"
                  style="marginbottom: 42px"
                  @mousedown.stop="an"
                  @mouseup.stop="song(Twofive.id)"
                  >{{ Twofive.one_tag }}</span
                ><br />
                <span
                  id="TwoPagePadTHree"
                  class="detailsxiangText"
                  @mousedown.stop="an"
                  @mouseup.stop="song(Twofive.id)"
                  >{{ Twofive.two_tag }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="threeImgddd"
          style="margintop: 98px"
          @mouseup="skipDetails(Twoseven.id)"
        >
          <div class="detailsListseds" style="float: left">
            <span
              class="detailsTitleText colorLu"
              @mousedown.stop="an"
              @mouseup.stop="song(Twoseven.id)"
              >{{ Twoseven.name }}</span
            ><br />
            <span
              class="detailsJianText colorLu"
              @mousedown.stop="an"
              @mouseup.stop="song(Twoseven.id)"
              >{{ Twoseven.one_tag }}</span
            ><br />
            <span
              class="detailsxiangText colorLu"
              @mousedown.stop="an"
              @mouseup.stop="song(Twoseven.id)"
              >{{ Twoseven.two_tag }}</span
            >
          </div>
          <img :src="Twoseven.img" id="panzi" alt="" />
        </div>
      </div>
       <div class="page">
      <Pagetion :total="2" :pageSize="1" @ClickChange="handleCurrentChange"></Pagetion>
    </div>
          <footer>
    <div class="bottom">
        <div class="content">
            <div class="left">
                <div class="interview-count">
                    <span>网站访问量</span>
                    <ul>
                      <li v-for="(item,key) in site_fwl" :key="key">
                          {{item}}
                      </li>
                    </ul>
                </div>
                <dl>
                    <router-link tag="dd" :to="item.menu_address" v-for="(item,key) in listed" :key="key">{{item.menu_name}}</router-link>
                    <dd></dd>
                </dl>
                <div class="copy-right">
                    <p>
                        <img src="../assets/img/jinghui.png" alt="" srcset="">
                        <span>
                            {{banquan.site_beian}}
                            <!-- 京公网安备 1101010238123号 -->
                        </span>
                        <!-- <span>
                            京ICP备050612311号-1
                        </span> -->
                    </p>
                    <p class="copy">
                      {{banquan.site_banquan}}
                        <!-- © 2020 - 现在 顺义博物馆  网站建设:  迦尼集团旗下品牌•易龙天 -->
                    </p>
                </div>
            </div>
            <div class="right">
                <div class="Footer_margin">
                    <img src="../assets/qrCode.png" alt="" srcset="">
                    <p>顺义文旅官方账号</p>
                </div>
                <div>
                    <img src="../assets/erweima.png" alt="" srcset="">
                    <p>顺义旅游官方账号</p>
                </div>
            </div>
        </div>
      </div>
  </footer>

    </div>

   
  </div>

  </div>
</template>

<script>
import Footer from "../components/footer";
import { fetch } from "../api/https";
import Pagetion from '../components/pagetion/pagetion.vue';
export default {
  data() {
    return {
      goods_id: 5,
      flag: true,
      // page:1,
      listOne: [],
      listTwo: [],
      banners: "",
      arr: [],
      list: "",
      listed: "",
      site_fwl:'',
      banquan:'',
      timeLength: 0,
      oldtime: 0,
      one:1,
      two:2,
      three:3,
      four:4,
      five:5,
      six:6,
      seven:7,
      eight:8,
      night:9,
      ten:10,
      ones:11,
      twos:0,
      Twoone:0,
      Twotwo:0,
      Twothree:0,
      Twofour:0,
      Twofive:0,
      Twosix:0,
      Twoseven:0,
      Twoling:0
    };
  },
  components: {
    Footer,
    Pagetion,
  },
  created() {},
  mounted() {
          fetch("/getFootMenu").then((res) => {
        this.listed = res.data;
      });
      fetch("/footerinfo").then((res) => {
        this.banquan = res.data;
        this.site_fwl = String(res.data.site_fwl);
      });
    this.axio();
      fetch('/getmenu')
            .then(res => {
              document.title = res.data[4].child[1].menu_seo_title
            })
  },
  methods: {
    an() {
      //鼠标按下
      this.timeLength = new Date().getTime();
    },
    song(id) {
      //鼠标松开 判断鼠标点击的时间
      this.oldtime = new Date().getTime();
      if (this.oldtime - this.timeLength < 130) {
        this.$router.push("/3D_details?goods_id=" + id);
      }
    },
    axio() {
      fetch("/collect/index", { type: 2, page: 1 })
        .then((result) => {
          this.listOne = result.data.data;
          this.ones = this.listOne[0]
          this.one = this.listOne[1]
          this.two = this.listOne[2]
          this.three = this.listOne[3]
          this.four = this.listOne[4]
          this.five = this.listOne[5]
          this.six = this.listOne[6]
          this.seven = this.listOne[7]
          this.eight = this.listOne[8]
          this.night = this.listOne[9]
          this.ten = this.listOne[10]
          this.twos = this.listOne[11]
        })
        .catch((err) => {});
      fetch("/collect/index", { type: 2, page: 2 })
        .then((result) => {
          this.listTwo = result.data.data;
          this.Twoone = this.listTwo[1]
          this.Twotwo = this.listTwo[2]
          this.Twothree = this.listTwo[3]
          this.Twofour = this.listTwo[4]
          this.Twofive = this.listTwo[5]
          this.Twosix = this.listTwo[6]
          this.Twoseven = this.listTwo[7]
          this.Twoling = this.listTwo[0]

        })
        .catch((err) => {});
      fetch("/getmenu")
        .then((res) => {
          this.banners = res.data[4].menu_img;
        })
        .catch((err) => {});
      // document.getElementsByClassName('btn-prev').innerText = "上一页"
    },
    skipDetails(id) {
      //模拟当前点击的uid之
      this.$router.push("/3D_details?goods_id=" + id);
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      // window.scrollTo(0, 0);
      // this.page = val
      // this.axio();
      if (val == 1) {
        this.flag = true;
      } else {
        this.flag = false;
      }
      // this.page = val;
      // this.requstOut();
      document.documentElement.getElementsByClassName(".all").scrollTop = 0;
    },
  },
};
</script>

<style scoped lang="scss">
footer {
  width: 100%;
  height: 295px;
  .bottom {
    width: 100%;
    background: url("../assets/footerBack.png");
    background-size: 100% 100%;
    height: 295px;
    position: relative;
    background-color: #a78d63;
    padding: 80px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    // .content {
    //     height: 140px;
    // }
    .content {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
    }
    & .left {
      // border: 1px solid green;
      text-align: center;
      color: #fff;
      width: 100%;
      // 访问数量
      & .interview-count {
        display: flex;
        justify-content: center;
        align-items: center;
        // border: 1px solid red;
        margin: 0 auto;
        width: 80%;
        span {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgb(243, 243, 245);
        }

        ul {
          display: flex;
          margin-left: 6px;
          & li {
            width: 20px;
            height: 28px;
            // background: #333333;
            // opacity: 0.1;
            box-sizing: border-box;
            line-height: 28px;
            text-align: center;
            background-color: rgba(245, 245, 245, 0.1);
            margin-left: 6px;
          }
        }
      }
      & dl {
        width: 360px;
        height: 15px;
        padding: 0;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        padding-right: 2px;
        margin-top: 24px;
        margin-bottom: 26px;
        & dd {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          float: left;
        }
      }

      & .copy-right {
        clear: both;
        color: #ede7df;
        text-align: center;
        font-size: 14px;
        position: relative;
        & img {
          vertical-align: middle;
          // float: left;
          // margin-top: ;
          margin: 0;
          // left: 760px;
          width: 16px;
          height: 19px;
        }
        & p {
          margin-bottom: 8px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
        }
      }
    }
    dd {
      cursor: pointer;
    }

    .copy {
      font-size: 14px;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
    }
  }
  .Footer_margin {
    margin-right: 30px;
  }
  .right {
    position: absolute;
    right: 18.5%;
    top: 100px;
    color: #f4f4f4;
    // border: 1px solid red;
    & div {
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;

      & img {
        width: 83px;
        height: 83px;
      }

      & p {
        transform: scale(0.9);

        margin-top: 8px;
      }
    }
  }
}
* {
  margin: 0;
  padding: 0%;
}
.allbaoGe_all {
  height: auto;
  width: 100%;
  overflow: hidden;
}
.allbaoGe_alltwo {
  height: auto;
  width: 100%;
  overflow: hidden;
}
.image {
  height: 600px;
  width: 100%;
  /* background-color: yellowgreen; */
  margin-bottom: 60px;
}
.image img {
  height: 100%;
  width: 100%;
}
.paione {
  width: 1600px;
  height: auto;
  margin: 0 auto;
  /* background-color: wheat; */
}
.paitwo {
  width: 1600px;
  height: auto;
  margin: 0 auto;
  /* background-color: wheat; */
}
.OneIm {
  width: 100%;
  /* display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between; */
  margin-top: 108px;
  height: 790px;
}
.OneImgs {
  width: 100%;
  /* display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between; */
  height: 790px;
  margin-top: 29px;
}
.OneLeft {
  width: 790px;
  height: 790px;
  float: left;
  /* background-color: yellow; */
  background: url("../assets/numberCourt/XYDGSY.png");
}
.fourLeft {
  width: 790px;
  height: 792px;
  float: left;
  /* background-color: yellow; */
  background: url("../assets/numberCourt/PZ.png");
}
.Oneright {
  float: right;
  width: 790px;
  height: 790px;
  /* background-color: green; */
  background: url("../assets/numberCourt/bg.png");
  text-align: center;
  position: relative;
}
.baies{
  width: 100%;
  height: 100%;
}
#fontfive {
  font-size: 25px;
}
#fontSix {
  font-size: 26px;
}
#fontSeven {
  font-size: 27px;
}
.Fourright {
  float: right;
  width: 790px;
  height: 792px;
  /* background-color: green; */
  background: url("../assets/numberCourt/XTJ.png");
  text-align: center;
  position: relative;
}
.Oneright img {
  height: 711px;
  width: 593px;
  position: absolute;
  top: -110px;
  left: 50%;
  margin-left: -298px;
}
.TwoPage {
  text-align: center;
  /* height: 500px; */
  width: 100%;
  padding-top: 125px;
  box-sizing: border-box;
}
#TwoPagePadTop{
  padding-top: 107px ;

}
#TwoPagePadTwo{
  padding-top: 80px ;

}
#TwoPagePadTHree{
  padding-top: 25px ;

}
.panzi {
  text-align: center;
  /* height: 500px; */
  width: 100%;
  padding-top: 117px;
  box-sizing: border-box;
}
.detailsList {
  text-align: center;
  /* height: 500px; */
  width: 100%;
  padding-top: 68px;
  box-sizing: border-box;
}
#colorJu {
  color: #a06f4c;
}
.detailsListed {
  text-align: center;
  /* height: 500px; */
  width: 100%;
  padding-top: 68px;
  box-sizing: border-box;
  margin-top: 20px;
}
.detailsLists {
  text-align: center;
  /* height: 500px; */
  width: 50%;
  padding-top: 200px;
  padding-left: 30px;
  box-sizing: border-box;
}
.tongxiang {
  text-align: center;
  /* height: 500px; */
  width: 580px;
  margin-left: 140px;
  padding-top: 165px;
  box-sizing: border-box;
  margin-right: 196px;
}
#tongxianglu {
  width: 605px;
  height: 478px;
  margin-top: -50px;
}
.detailsListseds {
  text-align: center;
  /* height: 500px; */
  width: 50%;
  padding-top: 156px;
  padding-left: 30px;
  box-sizing: border-box;
}
.detailsLis {
  text-align: center;
  /* height: 500px; */
  width: 50%;
  padding-top: 165px;
  padding-left: 30px;
  box-sizing: border-box;
}
.detailsListsd {
  text-align: center;
  /* height: 500px; */
  width: 80%;
  padding-top: 200px;
  padding-left: 30px;
  box-sizing: border-box;
  position: relative;
}
.detailsListsdss {
  text-align: center;
  /* height: 500px; */
  padding-top: 252px;
  padding-right: 93px;
}

#colorLu {
  color: #73732e;
}
.detailsTitleText {
  font-size: 40px;
  font-family: "PingFang-SC-Medium" !important;
  font-weight: 350;
  color: #666666;
  /* margin-bottom: 40px; */
  color: #666666;
  display: block;
  /* height: 42px; */
  line-height: 60px;
}
.detailsTitleTextdeng {
  font-size: 40px;
  font-family: "PingFang-SC-Medium" !important;
  font-weight: 350;
  color: #666666;
  margin-bottom: 40px;
  display: block;
  height: 42px;
}
.detailsTitleTextBottom {
  font-size: 40px;
  font-family: "PingFang-SC-Medium" !important;
  font-weight: 350;
  color: #666666;
  margin-bottom: 40px;
  line-height: 45px;
  display: block;
  height: 42px;
}
#sanzu {
  color: #695844;
}
.detailsTitleTexts {
  font-size: 40px;
  font-family: "PingFang-SC-Medium" !important;
  font-weight: 350;
  margin-bottom: 25px;
  color: #666666;
  display: block;
}
.detailsJianText {
  display: block;
  font-size: 30px !important;
  font-family: "SimSun" !important;
  font-weight: 400;
  color: #666666;
  /* margin-bottom: 13px; */
  min-height: 26px;
}
.detailsxiangText {
  display: block;
  margin-top: -10px;
  font-size: 18px;
  font-family: "PingFang SC" !important;
  font-weight: 500;
  color: #666666;
  font-weight: 500;
}
.mad {
  margin-top: 80px;
}
.colorZong {
  color: #a06f4c;
}
.suzhi_head {
  height: 500px;
  background-color: transparent !important;
}
.twoImgs {
  width: 100%;
  /* display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between; */
  height: 1251px;
  /* background-color: hotpink; */
  margin-top: 30px;
  margin-bottom: 30px;
}
.twoLeft {
  width: 790px;
  height: 100%;
  float: left;
  /* background-color: yellow; */
  background: url("../assets/numberCourt/NFBYMP.png");
  background-size: cover;
}
.twoLefted {
  width: 790px;
  height: 100%;
  float: left;
  /* background-color: yellow; */
  background: url("../assets/numberCourt2/tuceng8.png");
  background-size: cover;
}
.tworight {
  float: right;
  width: 790px;
  height: 790px;
  /* background-color: green; */
  /* background: url("../assets/numberCourt/bg.png"); */
  text-align: center;
  position: relative;
}
.twoLefts {
  width: 790px;
  height: 100%;
  float: right;
  /* background-color: yellow; */
  background: url("../assets/numberCourt/NFBYMP.png");
  background-size: cover;
}
.twoLeftsed {
  width: 790px;
  height: 100%;
  float: right;
  /* background-color: yellow; */
  background: url("../assets/numberCourt2/tuceng11.png");
  background-size: cover;
}
.tworights {
  float: left;
  width: 790px;
  height: 790px;
  /* background-color: green; */
  /* background: url("../assets/numberCourt/bg.png"); */
  text-align: center;
  position: relative;
}
.tworight div {
  height: 612px;
  width: 790px;
}
.tworights div {
  height: 612px;
  width: 790px;
}
.twoRighOne {
  background: url("../assets/numberCourt/HDWDM.png");
  background-size: cover;
}
.twoRighOneeds {
  height: 612px;
  width: 790px;
  background: url("../assets/numberCourt2/tuceng24.png");
  background-size: cover;
}
.twoRighOneedsed {
  height: 612px;
  width: 790px;
  background: url("../assets/numberCourt2/tuceng24.png");
  background-size: cover;
}
.twoRighOneed {
  background: url("../assets/numberCourt2/celts.png");
  background-size: cover;
}
.twoRighTwo {
  margin-top: 27px;
  background: url("../assets/numberCourt/XSF.png");
  background-size: cover;
}
.twoRighTwoeds {
  height: 612px;
  width: 790px;
  background-size: cover;
}
.twoRighTwoedsed {
  height: 612px;
  width: 790px;
  background: url("../assets/numberCourt2/lazhu.png");
  background-size: 100% 100%;
  margin-top: 30px;
}
.twoRighTwoed {
  margin-top: 27px;
  background: url("../assets/numberCourt2/tuceng6.png");
  background-size: cover;
}
.threeImg {
  width: 1600px;
  height: 613px;
  margin-top: 30px;
  /* padding-top: 30px; */
  margin: 0 auto;
  position: relative;
  background: url("../assets/numberCourt/CXLCopy.png");
  margin-bottom: 29px;
}
.threeImgddd {
  width: 1600px;
  height: 480px;
  margin-top: 30px;
  /* padding-top: 30px; */
  margin: 0 auto;
  position: relative;
  background: url("../assets/numberCourt2/disc.png");
}
.threeImgs {
  width: 1600px;
  height: 513px;
  margin-top: 30px;
  /* padding-top: 30px; */
  margin: 0 auto;
  position: relative;
  background: url("../assets/numberCourt2/bgCopy.png") no-repeat;
  margin-bottom: 29px;
}
.threeImgesed {
  width: 1600px;
  height: 513px;
  margin-top: 108px;
  /* padding-top: 30px; */
  margin: 0 auto;
  position: relative;
  background: url("../assets/numberCourt2/censer.png") no-repeat;
  margin-bottom: 29px;
}
.tongluImg {
  width: 1600px;
  height: 513px;
  margin-top: 108px;
  /* padding-top: 30px; */
  margin: 0 auto;
  position: relative;
  background: url("../assets/numberCourt2/censer.png") no-repeat;
  margin-bottom: 29px;
}
.tongluImg img {
  width: 605px;
  height: 478px;
}
.threeImg img {
  height: 728px;
  width: 722px;
  float: right;
  position: absolute;
  right: 30px;
  bottom: -114px;
  z-index: 999;
}
#panzi {
  height: 596px;
  width: 592px;
  float: right;
  position: absolute;
  right: 61px;
  top: -151px;
  z-index: 999;
}
.pan {
  color: #32623e;
}

.fiveImg {
  height: 478px;
  width: 1600px;
  margin-top: 30px;
  margin: 0 auto;
  position: relative;
  background: url("../assets/numberCourt/juxing513.png");
  background-size: 100% 100%;
  box-sizing: border-box;
  margin-bottom: 27px;
  margin-top: 22px;
}
.fiveImg img {
  height: 523px;
  width: 794px;
  float: right;
  position: absolute;
  right: 0;
  top: -50px;
  z-index: 999;
}
.sixLeft {
  background: url("../assets/numberCourt/QCHZ.png");
  width: 790px;
  height: 790px;
  float: left;
  /* background-color: yellow; */
}
.sixRight {
  /* background: url('../assets/numberCourt/QCHZ.png'); */
  width: 790px;
  height: 790px;
  margin-left: 20px;
  float: left;
  /* background-color: yellow; */
  background: url("../assets/numberCourt/bg(1).png");
  position: relative;
}
.sixRight img {
  margin-left: 57px;
  height: 636px;
  width: 707px;
  position: absolute;
  top: 268px;
  left: 0;
  z-index: 999;
}
.sevenImg {
  margin-top: 20px;
  height: 682px;
  width: 1600px;
  background-size: cover;
  padding-bottom: 40px;
  position: relative;
  box-sizing: border-box;
  padding-left: 426px;
}
.seven {
  position: relative;
  float: right;
  height: 682px;
  width: 1076px;
  background: url("../assets/numberCourt/juxing514.png");
}
#sevenImgs {
  height: 379px;
  width: 917px;
  /* background: url("../assets/numberCourt/juxing514.png"); */
  float: left;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -190px;
  z-index: 999;
}

.page {
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-between;
  text-align: center;
  padding: 54px 0;
}
@media screen and (min-width: 1400px) and (max-width: 1500px) {
    .aaaaa {
      transform: scale(0.8) !important;
      transform-origin: top left !important;
      margin-left: 78px !important;
    }
    .bottom{
           transform: scale(1.33)  !important;
          margin-top: 57px !important;
          margin-left: 125px !important;
    }
    .allbaoGe_all{
      height: 5594px !important;
    }
    .allbaoGe_alltwo{
      height: 3940px !important;
    }
    .page{
          margin-left: 320px !important;
    }
    .page{
          transform: scale(1.1)
    }
}
@media screen  and (max-width: 1399px) {
    .aaaaa {
      transform: scale(0.8) !important;
      transform-origin: top left !important;
      margin-left: 40px !important;
    }
    .bottom{
           transform: scale(1.33)  !important;
          margin-top: 57px !important;
          margin-left: 125px !important;
    }
    .page{
          transform: scale(1.15)
    }
    .allbaoGe_all{
        height: 5594px !important;
    }
    .allbaoGe_alltwo{
      height: 3940px !important;    }
    .page{
          margin-left: 320px !important;
    }
}
@media screen and (min-width: 1501px) and (max-width: 1600px) {
    .aaaaa {
      transform: scale(0.9) !important;
      transform-origin: top left !important;
      margin-left: 48px !important;
    }
    .bottom{
      margin-top: 50px !important;
      transform: scale(1.2205) !important;
      margin-top: 56px !important;
    }
    .allbaoGe_all{
      height: 4330px !important;
    }
    .allbaoGe_alltwo{
      height: 4335px !important;
      
    }
    .page{
          margin-left: 150px !important;
    }
}

</style>
<style>
/* .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #c19c66;
  color: #fff;
}
.el-pagination.is-background .el-pager li.active {
  color: #fff;
  cursor: default;
}
.el-pagination.is-background .el-pager li:hover {
  color: #c19c66;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #c19c66;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: #c19c66;
  color: #fff;
}
.el-pagination span {
  line-height: 40px;
} */

</style>
